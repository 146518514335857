/**
 * Created by Giannis Kallergis on 2018-12-29
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
import _ from "lodash";

/** Components */
import Layout from "../components/Layout";
import Wrapper from "../components/Wrapper";
import SEO from "../components/SEO";
import Header from "../components/Header";
import Content from "../components/Content";
import SectionTitle from "../components/SectionTitle";
import AuthorHeader from "../components/AuthorHeader";

const Title = styled.h3`
  position: relative;
  text-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  margin-bottom: 0.75rem;
`;

// noinspection JSUnusedGlobalSymbols
export default class CategoriesPage extends React.PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      allMarkdownRemark: PropTypes.shape({
        group: PropTypes.arrayOf(
          PropTypes.shape({
            fieldValue: PropTypes.string.isRequired,
            totalCount: PropTypes.number.isRequired,
          })
        ).isRequired,
      }).isRequired,
    }).isRequired,
  };

  /** Renderers */

  _renderCategory = category => {
    const title = category.fieldValue;
    const slug = _.kebabCase(title);
    const totalCount = category.totalCount;
    return (
      <Title key={slug}>
        <Link to={`/categories/${slug}`}>{title}</Link> ({totalCount})
      </Title>
    );
  };

  render() {
    const categories = this.props.data.allMarkdownRemark.group;
    return (
      <Layout>
        <SEO title="Categories" />
        <Wrapper>
          <Header>
            <AuthorHeader />
          </Header>
          <Content hasHeader>
            <SectionTitle>Categories</SectionTitle>
            {categories.map(this._renderCategory)}
          </Content>
        </Wrapper>
      </Layout>
    );
  }
}

// noinspection JSUnusedGlobalSymbols
export const CategoriesPageQuery = graphql`
  query CategoriesPageQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date] }
      filter: { frontmatter: { draft: { ne: true } } }
    ) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
  }
`;
